$(document).ready(function () {
    //USED LIBRARIES
    //https://github.com/ganlanyuan/tiny-slider

    // //TINY SLIDERS HOME SLIDER ITEMS
    // [].forEach.call(document.querySelectorAll('.item-slider-main'), function (el) {
    //     tns({
    //         container: el,
    //         loop: true,
    //         slideBy: 1,
    //         gutter: 12,
    //         edgePadding: 20,
    //         nav: false,    
    //         autoplay: false,
    //         speed: 400,
    //         autoplayButtonOutput: false,
    //         mouseDrag: true,
    //         lazyload: true,
    //         responsive: {
    //             640: {
    //                 items: 2,
    //             },
                
    //             768: {
    //                 items: 3,
    //             }
    //         }
        
    //     });
    // });


    // START TINY SLIDERS ===============================================================
    if(document.querySelector('.item-slider-main')){
        //TINY SLIDERS HOME SLIDER ITEMS
        var myNodeList = document.querySelectorAll('.item-slider-main'); // grabs some <li>
        // Uncaught TypeError: Object # has no method 'forEach' 
        myNodeList.forEach(function (item) {
            tns({
                container: item,
                loop: true,
                slideBy: 1,
                gutter: 12,
                edgePadding: 20,
                nav: false,    
                autoplay: false,
                speed: 400,
                autoplayButtonOutput: false,
                controlsText: ["", ""],
                mouseDrag: true,
                lazyload: true,
                responsive: {
                    350: {
                        items: 1,
                    },
                    400: {
                        items: 2,
                    },
                    500: {
                        items: 3,
                    },
                    640: {
                        items: 3,
                    },
                    
                    768: {
                        items: 2,
                    },
                    1040: {
                        items: 3,
                    }
                }
            });
        });
    }

    if(document.querySelector('.category-main-wrapper')){
        //CATEGORY PAGE LEFT SLIDER
        var leftslider  = document.querySelector('.category-left-slider');
        var slider1 = tns({
            container: leftslider,
            mode: "gallery",
            loop: true,
            nav: false,    
            autoplay: false,
            speed: 800,
            autoHeight: true, 
            autoplayButtonOutput: false,
            // controlsText: ["", ""],
            mouseDrag: true,
            lazyload: true,
            controlsText:['<div class="left-slider-arrow"><i class="fas fa-chevron-left"></i></div>', '<div class="left-slider-arrow"><i class="fas fa-chevron-right"></i></div>']
        });

        //CATEGORY PAGE RIGHT SLIDER
        var rightslider  = document.querySelector('.category-right-slider');
        var slider2 = tns({
            container: rightslider,
            axis: "vertical",
            items: 3,
            swipeAngle: false,
            loop: true,
            nav: false,    
            autoplay: false,
            speed: 800,
            autoplayButtonOutput: false,
            mouseDrag: true,
            lazyload: true,
            controlsText:['<div class="left-slider-arrow"><i class="fas fa-chevron-left"></i></div>', '<div class="left-slider-arrow"><i class="fas fa-chevron-right"></i></div>']
        });
    }
    // END TINY SLIDERS ===============================================================
});